import { useState } from 'react'
import {
  BiDotsVerticalRounded,
  BiDownload,
  BiLink,
  BiPencil,
  BiTrash,
} from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  ButtonGroupProps,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react'

import { GetSubmissionDto } from '~shared/dtos'

import { MAX_DOWLOAD_FILE_SIZE_IN_BYTES } from '~/constants/config'
import { useAdminUser } from '~/features/auth/hooks'
import { useCampaignPrivateKey } from '~/features/dashboard'
import { DownloadModal } from '~/features/download'
import { useToast } from '~/hooks/useToast'
import { decryptAndDownload, DownloadStatus } from '~/utils/download'

import { DeleteModal } from '../../delete/DeleteModal'
import { useCampaign } from '../hooks/campaigns.hooks'

export const SubmissionActionMenu = ({
  submission,
  onShare,
  isDisabled = false,
  ...rest
}: {
  submission: GetSubmissionDto
  onShare: (s: GetSubmissionDto) => void
  isDisabled?: boolean
} & ButtonGroupProps) => {
  const navigate = useNavigate()
  const documentSize = submission.documents.reduce(
    (total, doc) => total + doc.sizeInBytes,
    0,
  )
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure()
  const { adminUser } = useAdminUser()

  const { campaign } = useCampaign()
  const [progressInfo, setProgressInfo] = useState({
    progress: -1,
    info: DownloadStatus.INITIALIZING,
  })
  const campaignPrivateKey = useCampaignPrivateKey(submission.campaignId)
  const toast = useToast()

  if (!campaign) {
    return <Spinner />
  }
  return (
    <Box flex={1}>
      <Menu placement="end-start">
        <MenuButton
          as={Button}
          variant={'none'}
          border={'none'}
          padding={0}
          color={'interaction.main.default'}
          onClick={(e) => e.stopPropagation()}
        >
          <Icon as={BiDotsVerticalRounded} boxSize={5} />
        </MenuButton>
        <MenuList mt={-3}>
          <MenuItem
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={(e) => {
              e.stopPropagation()
              onShare(submission)
            }}
          >
            <HStack spacing="12px">
              <Icon as={BiLink} boxSize={'20px'} />
              <Text textStyle="body-1">Share link</Text>
            </HStack>
          </MenuItem>
          <MenuItem
            onClick={(e) => {
              e.stopPropagation()
              navigate(
                `/admin/collections/${submission.campaignId}/submission/${submission.id}/edit`,
              )
            }}
          >
            <HStack spacing="12px">
              <Icon as={BiPencil} boxSize={'20px'} />
              <Text textStyle="body-1">Edit link</Text>
            </HStack>
          </MenuItem>

          <MenuItem
            isDisabled={
              submission.documents.length == 0 ||
              documentSize > MAX_DOWLOAD_FILE_SIZE_IN_BYTES
            }
            onClick={async (e) => {
              e.stopPropagation()
              if (campaignPrivateKey === null) {
                onOpen()
                return
              }
              try {
                await decryptAndDownload(
                  submission.documents,
                  campaignPrivateKey,
                  setProgressInfo,
                  adminUser?.id,
                )
              } catch (err) {
                toast({
                  status: 'error',
                  description:
                    err instanceof Error
                      ? err.message
                      : 'Something went wrong.',
                })
              }
            }}
          >
            <HStack spacing="12px">
              <Icon as={BiDownload} boxSize={'20px'} />
              <Text textStyle="body-1">Download all files</Text>
            </HStack>
          </MenuItem>
          <MenuItem
            isDisabled={submission.documents.length == 0}
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()
              onDeleteModalOpen()
            }}
            color="interaction.critical.default"
          >
            <HStack spacing="12px">
              <Icon as={BiTrash} boxSize={'20px'} />
              <Text textStyle="body-1">Delete all files</Text>
            </HStack>
          </MenuItem>
        </MenuList>
      </Menu>
      <DeleteModal
        isOpen={isDeleteModalOpen}
        onClose={onDeleteModalClose}
        submission={submission}
        documents={submission.documents}
      />
      <DownloadModal
        isOpen={isOpen}
        onClose={onClose}
        campaignId={campaign.id}
        campaignName={campaign.name}
        campaignPublicKey={campaign.publicKey}
        documents={submission.documents}
        setProgress={setProgressInfo}
      />
    </Box>
  )
}
