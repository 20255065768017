import {
  IsDateString,
  IsDefined,
  IsEmail,
  IsNumber,
  IsOptional,
  IsString,
  Matches,
  MaxLength,
} from 'class-validator'

import {
  SUBMISSION_ID_REGEX,
  SUBMISSION_MAX_ID_LEN,
  SUBMISSION_MAX_NAME_LEN,
  SUBMISSION_NAME_REGEX,
} from '../constants'

import { GetDocumentDto } from './documents.dto'

export enum SubmissionUpdateType {
  EDIT = 'EDIT',
}

export class CreateSubmissionReq {
  @IsDefined()
  @IsString()
  @Matches(SUBMISSION_ID_REGEX)
  @MaxLength(SUBMISSION_MAX_ID_LEN)
  internalId: string

  @IsOptional()
  @IsString()
  @Matches(SUBMISSION_NAME_REGEX)
  @MaxLength(SUBMISSION_MAX_NAME_LEN)
  name?: string

  @IsDefined()
  @IsNumber()
  campaignId: number

  @IsDefined()
  @IsString()
  instructions: string

  @IsDefined()
  @IsDateString()
  expiresAt: Date
}

export interface GetSubmissionPublicDto {
  id: number
  publicId: string
  name?: string
  campaignId: number
  expiresAt: string // ISO datestring
  filesExpireAt: string | null // ISO datestring
  instructions: string
  contactEmail: string | null
  campaignName: string
  campaignPublicKey: string
}

export interface GetSubmissionDto extends GetSubmissionPublicDto {
  internalId: string
  documents: GetDocumentDto[]
}

export class NotifySubmissionReq {
  @IsDefined()
  @IsEmail()
  email: string
}

export class UpdateSubmissionReq {
  @IsOptional()
  @IsString()
  instructions: string

  @IsOptional()
  @IsDateString()
  expiresAt?: Date
}
