import { useEffect } from 'react'
import {
  Button,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'

import Announcement from '~/assets/Announcement.svg'

export const AnnnouncementModal = (): JSX.Element | null => {
  const { onClose, onOpen, isOpen } = useDisclosure()

  useEffect(() => {
    const isAnnouncementSeen = localStorage.getItem('announcement') === 'true'
    if (!isAnnouncementSeen) {
      localStorage.setItem('announcement', 'true')
      onOpen()
    }
  }, [])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pb={0}>
          <Text textStyle="h4">We&apos;ve got something new for you!</Text>
          <ModalCloseButton size="md" mr={1} />
        </ModalHeader>
        <VStack px="32px" pt="24px">
          <Image src={Announcement} alt="Announcement" />
        </VStack>

        <ModalFooter pt={6} pb={12}>
          <Button type="submit" onClick={onClose}>
            Got it
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
