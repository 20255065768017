import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { BiErrorCircle } from 'react-icons/bi'
import {
  Box,
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Progress,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Button } from '@opengovsg/design-system-react'
import { WretchError } from 'wretch/resolver'

import { isGovSgOrWhitelistedEmail } from '~shared/decorators/is-gov-sg-or-whitelisted-email'

import { Loading } from '~/components/Loading'
import { useAdminAuth } from '~/features/auth'
import { useToast } from '~/hooks/useToast'

import {
  useAddCollaboratorAccess,
  useGetCampaignCollaborators,
  useRemoveCollaboratorAccess,
} from '../../hooks/campaigns.hooks'
import {
  getToastMessage,
  ToastMessageType,
} from '../../toasts/collaboratorToast'
import { CollaboratorRow } from '../CollaboratorRow'

interface ManageCollaboratorAccessModalProps {
  isOpen: boolean
  onClose: () => void
  campaignName: string
  campaignId: number
  campaignOwnerEmail: string | null
}

interface CollaboratorFormData {
  collaboratorEmail: string
}

export const ManageCollaboratorAccessModal = ({
  isOpen,
  onClose,
  campaignName,
  campaignId,
  campaignOwnerEmail,
}: ManageCollaboratorAccessModalProps): JSX.Element => {
  const toast = useToast({
    position: 'top',
    isClosable: true,
    variant: 'subtle',
    containerStyle: {
      width: '500px',
      maxWidth: '100%',
    },
  })

  const toastIdRef = useRef<string | number>()

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    watch,
  } = useForm<CollaboratorFormData>()

  const [error, setError] = useState<string | undefined>(undefined)

  const { collaborators, isDataLoading: isLoadingCollaborators } =
    useGetCampaignCollaborators(campaignId)

  const { mutateAsync, isLoading } = useAddCollaboratorAccess({
    onSuccess: (_res, { userEmail }) => {
      const toastMessage = getToastMessage(
        ToastMessageType.COLLABORATOR_ADD_SUCCESS,
        userEmail,
      )
      if (toastMessage) {
        if (toastIdRef.current && toast.isActive(toastIdRef.current)) {
          toast.update(toastIdRef.current, toastMessage)
        } else {
          toastIdRef.current = toast(toastMessage)
        }
      }
      reset()
    },
    onError: (err: WretchError) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      setError(err.json?.message as string)
    },
  })

  const { mutateAsync: mutateAsyncRemoveAccess } = useRemoveCollaboratorAccess({
    onSuccess: (_res, { userEmail }) => {
      const toastMessage = getToastMessage(
        ToastMessageType.COLLABORATOR_REMOVE_SUCCESS,
        userEmail,
      )
      if (toastMessage) {
        if (toastIdRef.current && toast.isActive(toastIdRef.current)) {
          toast.update(toastIdRef.current, toastMessage)
        } else {
          toastIdRef.current = toast(toastMessage)
        }
      }
    },
    onError: (err: WretchError) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      setError(err.json?.message as string)
    },
  })

  const onSubmit = handleSubmit(async (data) => {
    await mutateAsync({
      campaignId: campaignId,
      userEmail: data.collaboratorEmail,
    })
  })

  const { adminUser } = useAdminAuth()
  const isOwner = adminUser?.email === campaignOwnerEmail

  useEffect(() => {
    reset()
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        {/* padding to offset close bottom = 44px (width of close button) + 32px (right padding) */}
        <ModalHeader textStyle={'h4'} pr="76px">
          {campaignName !== undefined
            ? `Share access to "${campaignName}"`
            : 'Manage collaborators'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack alignItems={'left'} spacing="0px" pt="10px">
            <VStack align={'left'} spacing="4px" mb="10px">
              <Text
                textStyle={'subhead-1'}
                fontSize={'16px'}
                textColor={'grey.500'}
              >
                Collaborators will have full access to edit, share and view all
                submissions in this collection.
              </Text>
            </VStack>
            <form
              noValidate // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onSubmit={onSubmit}
            >
              <FormControl isInvalid={!!errors.collaboratorEmail || !!error}>
                <HStack mb="5px">
                  <InputGroup borderColor="grey.200">
                    <Input
                      type="email"
                      placeholder="Add collaborator emails"
                      {...register('collaboratorEmail', {
                        validate: {
                          validOfficerEmail: (email) =>
                            email.length <= 1 ||
                            isGovSgOrWhitelistedEmail(email) === true ||
                            'Email should be a valid public officer email.',
                        },
                      })}
                      isDisabled={isLoading}
                    />
                  </InputGroup>

                  <Button // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={(e) => {
                      e.stopPropagation() // Stop propagation to prevent clicking on elements behind the modal
                      onSubmit()
                    }}
                    isDisabled={isLoading}
                  >
                    Add
                  </Button>
                </HStack>
                <FormErrorMessage>
                  <HStack display={'flex'}>
                    <BiErrorCircle size={'20px'} />
                    <Text fontSize={'14px'}>
                      {errors.collaboratorEmail &&
                        errors.collaboratorEmail.message}
                      {error != undefined && error}
                    </Text>
                  </HStack>
                </FormErrorMessage>
                {isLoading && <Progress size="xs" isIndeterminate />}
              </FormControl>
            </form>
          </VStack>
          {isLoadingCollaborators ? (
            <Loading />
          ) : (
            <VStack alignItems={'left'} pb="48px" pt="24px">
              <Text textStyle={'h5'} mb="16px">
                People with access
              </Text>
              <Box maxHeight="200px" overflowY={'auto'}>
                {/* If the user is the owner, we only want to show the 'Owner' instead of 'Admin' as well instead of showing it twice */}
                {isOwner ? (
                  <CollaboratorRow
                    userEmail={adminUser?.email ?? 'Owner User'}
                    isOwner={true}
                  />
                ) : (
                  <>
                    <CollaboratorRow
                      userEmail={campaignOwnerEmail ?? 'Owner User'}
                      isOwner={true}
                    />
                    <CollaboratorRow
                      userEmail={adminUser?.email ?? 'Admin User'}
                      isAdmin={true}
                    />
                  </>
                )}
                {collaborators?.map(
                  (collaborator, index) =>
                    adminUser?.email !== collaborator &&
                    campaignOwnerEmail !== collaborator && (
                      <CollaboratorRow
                        key={index}
                        userEmail={collaborator}
                        onClickRemove={() => {
                          mutateAsyncRemoveAccess({
                            campaignId: campaignId,
                            userEmail: collaborator,
                          })
                        }}
                      />
                    ),
                )}
              </Box>
            </VStack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
