import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Flex, HStack, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'

import { GetSubmissionDto } from '~shared/dtos'

import { SubmissionActionMenu } from './SubmissionActionMenu'

export const Submission = ({
  submission,
  onShare,
}: {
  submission: GetSubmissionDto
  onShare: (s: GetSubmissionDto) => void
}) => {
  const { documents, internalId, id } = submission
  const navigate = useNavigate()

  const lastUpdatedAt = useMemo(() => {
    const documentUpdatedAt = documents
      .map((d) => dayjs(d.updatedAt))
      .sort((a, b) => b.diff(a))

    return documentUpdatedAt[0]?.format('DD/MM/YYYY hh:mm a') ?? '--'
  }, [documents])
  // Disable the submission if its files have already expired
  const isSubmissionDisabled =
    !!submission.filesExpireAt &&
    dayjs(submission.filesExpireAt) < dayjs().startOf('day')

  const documentSize = submission.documents.reduce(
    (total, doc) => total + doc.sizeInBytes,
    0,
  )

  return (
    <HStack
      align="center"
      bgColor={isSubmissionDisabled ? 'grey.100' : undefined}
      _hover={
        isSubmissionDisabled
          ? undefined
          : {
              bgColor: 'interaction.main-subtle.default',
              cursor: 'pointer',
            }
      }
      onClick={
        isSubmissionDisabled
          ? undefined
          : () =>
              navigate(
                `/admin/collections/${submission.campaignId}/submission/${id}`,
              )
      }
      py={'16px'}
      px={'40px'}
      textColor="base.content.strong"
      lineHeight={'24px'}
      spacing="12px"
    >
      <Text flex={3} textStyle={'body-1'}>
        {internalId}
      </Text>
      <Text textStyle={'body-1'} flex={1}>
        {documents.length}
      </Text>
      <Text textStyle={'body-1'} flex={2}>
        {lastUpdatedAt}
      </Text>
      <SubmissionActionMenu
        submission={submission}
        onShare={onShare}
        isDisabled={isSubmissionDisabled}
        flex={1}
      />
    </HStack>
  )
}

export const SubmissionHeader = () => {
  return (
    <HStack
      fontWeight="500"
      w="full"
      py="22px"
      px="40px"
      textColor={'interaction.sub.default'}
      spacing="12px"
    >
      <Text flex={3} textStyle={'subhead-2'}>
        Unique identifier number (UIN)
      </Text>
      <Text flex={1} textStyle={'subhead-2'}>{`No. of files`}</Text>
      <Text flex={2} textStyle={'subhead-2'}>{`Last submission`}</Text>
      <Text flex={1} textStyle={'subhead-2'}>
        Actions
      </Text>
    </HStack>
  )
}
