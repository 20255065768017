import { useMemo, useState } from 'react'
import { BiPlus, BiSearch } from 'react-icons/bi'
import { MdClose } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Spinner } from '@opengovsg/design-system-react'
import _debounce from 'lodash/debounce'

import DocumentsFlying from '~/assets/DocumentsFlying.svg'
import { useAdminAuth } from '~/features/auth'
import { useGetCampaigns } from '~/features/dashboard/hooks/campaigns.hooks'

import { Campaigns, PAGE_SIZE } from './Campaigns/Campaigns'
import { EmptyDashboard } from './EmptyState/EmptyDashboard'
import { AnnnouncementModal } from './AnnouncementModal'
import { BasicAdminPage } from './BasicAdminPage'

export const DashboardPageV2 = (): JSX.Element => {
  const navigate = useNavigate()

  const { adminUser } = useAdminAuth()

  const [query, setQuery] = useState('')
  const [searchQuery, setSearchQuery] = useState('')

  const { count: totalCount } = useGetCampaigns(PAGE_SIZE, 0, adminUser?.id)

  const { campaigns, isCampaignsLoading, count } = useGetCampaigns(
    PAGE_SIZE,
    0,
    adminUser?.id,
    searchQuery,
  )

  const debouncedSearch = useMemo(
    () =>
      _debounce((value: string) => {
        setSearchQuery(value)
      }, 300),
    [],
  )

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
    debouncedSearch(event.target.value)
  }
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      debouncedSearch(query)
    }
  }

  if (isCampaignsLoading) {
    return <Spinner />
  }

  if (!searchQuery.length && !campaigns?.length) {
    return <EmptyDashboard />
  }

  return (
    <BasicAdminPage
      title={
        searchQuery.length
          ? `Showing ${count ?? 0} of ${totalCount ?? 0} collections`
          : 'All collections'
      }
      buttons={
        <>
          <InputGroup>
            <InputLeftElement width="3rem">
              <BiSearch color="gray.300" />
            </InputLeftElement>
            <Input
              placeholder="Search collection"
              value={query}
              onChange={handleChange}
              onKeyDown={handleKeyPress}
              pl="3rem"
              pr="3rem"
              borderRadius={'4px'}
              focusBorderColor="green.500"
            />
            <InputRightElement
              width="3rem"
              borderRadius={'100px'}
              _hover={{ cursor: 'pointer' }}
              onClick={() => {
                setQuery('')
                setSearchQuery('')
              }}
            >
              <MdClose color="gray.300" />
            </InputRightElement>
          </InputGroup>
          <Button
            leftIcon={<BiPlus fontSize="20px" />}
            justifyContent="start"
            onClick={() => navigate('/admin/collections/create')}
            fontSize={'16px'}
          >
            Create collection
          </Button>
        </>
      }
    >
      {searchQuery.length && count == 0 ? (
        <VStack spacing="20px" justify={'center'} height="70vh">
          <Image src={DocumentsFlying} mb="24px" />
          <VStack spacing="12px">
            <Text textStyle="h5" color="brand.primary.500">
              No results found
            </Text>
            <Text textStyle="h6" color="base.content.default">
              It seems we can’t find any results based on your search.
            </Text>
          </VStack>
        </VStack>
      ) : (
        <Campaigns searchQuery={searchQuery} />
      )}
      <AnnnouncementModal />
    </BasicAdminPage>
  )
}
