import { useEffect, useState } from 'react'
import { Divider, Spinner, VStack } from '@chakra-ui/react'
import { Pagination } from '@opengovsg/design-system-react'

import { useAdminAuth } from '~/features/auth'
import { useGetCampaigns } from '~/features/dashboard/hooks/campaigns.hooks'

import { Campaign, CampaignHeader } from './Campaign'

export const PAGE_SIZE = 10

export interface CampaignsProps {
  searchQuery?: string
}

export const Campaigns = ({ searchQuery }: CampaignsProps) => {
  const { adminUser } = useAdminAuth()
  const [currentPage, setCurrentPage] = useState(0)
  const { campaigns, isCampaignsLoading, count } = useGetCampaigns(
    PAGE_SIZE,
    currentPage * PAGE_SIZE,
    adminUser?.id,
    searchQuery,
  )

  useEffect(() => {
    setCurrentPage(0)
  }, [searchQuery])

  if (isCampaignsLoading || !campaigns) return <Spinner />

  return (
    <VStack align="stretch" spacing={6}>
      <VStack align="stretch" spacing={0} divider={<Divider />}>
        <CampaignHeader />
        {campaigns.map((s) => (
          <Campaign key={s.id} campaign={s} query={searchQuery} />
        ))}
      </VStack>
      <VStack w={'100%'}>
        {count && (
          <Pagination
            totalCount={count}
            pageSize={PAGE_SIZE}
            onPageChange={(newPage) => setCurrentPage(newPage - 1)}
            currentPage={currentPage + 1}
          />
        )}
      </VStack>
    </VStack>
  )
}
