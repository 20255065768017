import { useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import {
  CreateSubmissionReq,
  DocumentStatus,
  GetSubmissionDto,
  NotifySubmissionReq,
  UpdateSubmissionReq,
} from '~shared/dtos'

import { adminQueryKeys } from '~/constants/query-keys'
import { useAdminAuth } from '~/features/auth'
import { useToast } from '~/hooks/useToast'
import { api } from '~/lib/api'

import { useCampaignId } from './campaigns.hooks'

export const useSubmissionId = (): { submissionId: number } => {
  const { submissionId } = useParams()
  if (!submissionId) throw new Error('No submissionId provided')
  return { submissionId: Number(submissionId) }
}

export const useSubmission = (submissionId?: number) => {
  const { adminUser } = useAdminAuth()
  const { campaignId } = useCampaignId()
  const { submissions, isSubmissionsLoading } = useGetSubmissions({
    userId: adminUser?.id,
    campaignId,
  })

  const submission = submissions?.find((s) => s.id === submissionId)

  return { submission, isSubmissionLoading: isSubmissionsLoading }
}

export const useGetSubmissions = ({
  userId,
  campaignId,
}: {
  userId?: number
  campaignId?: number
}) => {
  const { data, isLoading } = useQuery(
    adminQueryKeys.submissions({ userId, campaignId }),
    () =>
      api
        .url(
          `/users/${userId ?? ''}/submissions${
            campaignId ? `?campaignId=${campaignId}` : ''
          }`,
        )
        .get()
        .json<GetSubmissionDto[]>(),
    { enabled: !!userId },
  )

  const filteredSubmissions = data?.map((submission) => ({
    ...submission,
    documents: submission.documents.filter(
      (doc) => doc.status === DocumentStatus.SUBMITTED,
    ),
  }))

  return { submissions: filteredSubmissions, isSubmissionsLoading: isLoading }
}

export const useCreateSubmissionMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (res: GetSubmissionDto) => void
  onError?: () => void
} = {}) => {
  const { campaignId } = useCampaignId()
  const queryClient = useQueryClient()
  const { adminUser } = useAdminAuth()
  const userId = adminUser?.id

  return useMutation(
    async (body: CreateSubmissionReq): Promise<GetSubmissionDto> => {
      return await api
        .url(`/users/${userId ?? ''}/submissions`)
        .post(body)
        .json<GetSubmissionDto>()
    },
    {
      onSuccess: async (res: GetSubmissionDto) => {
        await queryClient.invalidateQueries(
          adminQueryKeys.submissions({ userId, campaignId }),
        )
        onSuccess?.(res)
      },
      onError,
    },
  )
}

export const useNotifySubmissionMutation = ({
  userId,
  submissionId,
}: {
  userId?: number
  submissionId: number
}) => {
  return useMutation(
    async (body: NotifySubmissionReq): Promise<GetSubmissionDto> => {
      return await api
        .url(`/users/${userId ?? ''}/submissions/${submissionId}/notify`)
        .post(body)
        .res()
    },
  )
}

export const useUpdateSubmissionMutation = ({
  userId,
  submissionId,
}: {
  userId?: number
  submissionId: number
}) => {
  const queryClient = useQueryClient()
  const { campaignId } = useCampaignId()
  return useMutation(
    async (body: UpdateSubmissionReq): Promise<GetSubmissionDto> => {
      return await api
        .url(`/users/${userId ?? ''}/submissions/${submissionId}`)
        .put(body)
        .res()
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          adminQueryKeys.submissions({ userId, campaignId }),
        )
      },
    },
  )
}

export const useDeleteDocumentsMutation = (
  {
    userId,
    submissionId,
  }: {
    userId?: number
    submissionId: number
  },
  {
    onSuccess,
    onError,
  }: {
    onSuccess?: () => void
    onError?: () => void
  } = {},
) => {
  const queryClient = useQueryClient()
  const { campaignId } = useCampaignId()
  const toast = useToast()
  return useMutation(
    async (): Promise<GetSubmissionDto> => {
      return await api
        .url(`/users/${userId ?? ''}/submissions/${submissionId}`)
        .delete()
        .res()
    },
    {
      onSuccess: async () => {
        toast({
          status: 'success',
          description: 'Successfully deleted submission documents.',
        })
        await queryClient.invalidateQueries(
          adminQueryKeys.submissions({ userId, campaignId }),
        )
        onSuccess?.()
      },
      onError: async () => {
        toast({
          status: 'error',
          description:
            'Failed to delete submission documents. Please try again later.',
        })
        onError?.()
      },
    },
  )
}
