export const adminQueryKeys = {
  base: 'admin' as const,
  announcementBanner: ['announcement-banner'] as const,
  id: (id?: number) => [adminQueryKeys.base, id] as const,
  campaigns: ({
    userId,
    searchQuery,
    limit,
    offset,
    campaignId,
  }: {
    userId?: number
    searchQuery?: string
    limit?: number
    offset?: number
    campaignId?: number
  } = {}) => {
    switch (true) {
      case Boolean(userId) &&
        Boolean(searchQuery) &&
        (Boolean(limit) || Boolean(offset)):
        return ['campaigns', userId, searchQuery, limit, offset] as const
      case Boolean(userId) && (Boolean(limit) || Boolean(offset)):
        return ['campaigns', userId, limit, offset] as const
      case Boolean(limit) || Boolean(offset):
        return ['campaigns', limit, offset] as const
      case Boolean(campaignId):
        return ['campaigns', campaignId] as const
      default:
        return ['campaigns'] as const
    }
  },
  collaborators: (campaignId?: number) =>
    campaignId
      ? (['collaborators', campaignId] as const)
      : (['collaborators'] as const),
  submissions: ({
    userId,
    campaignId,
  }: {
    userId?: number
    campaignId?: number
  }) => {
    return campaignId
      ? ([...adminQueryKeys.id(userId), 'submissions', campaignId] as const)
      : ([...adminQueryKeys.id(userId), 'submissions'] as const)
  },
}

export const publicQueryKeys = {
  base: 'public' as const,
  submission: (submissionPublicId: string) =>
    [publicQueryKeys.base, submissionPublicId] as const,
}
