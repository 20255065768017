import { useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { BiMailSend } from 'react-icons/bi'
import { WarningIcon } from '@chakra-ui/icons'
import {
  FormControl,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UseDisclosureReturn,
  VStack,
} from '@chakra-ui/react'
import { FormLabel, Infobox } from '@opengovsg/design-system-react'
import dayjs from 'dayjs'

import { GetSubmissionDto } from '~shared/dtos'

import { CopyButton } from '~/components/CopyButton'
import { MultiEmailInput } from '~/components/MultiEmailInput'
import { HOSTNAME_WITH_PROTOCOL } from '~/constants/config'
import { useAdminAuth } from '~/features/auth'
import { useToast } from '~/hooks/useToast'

import { useNotifySubmissionMutation } from '../hooks/submissions.hooks'

type ShareSubmissionModalProps = UseDisclosureReturn & {
  submission: GetSubmissionDto
}

type ShareSubmissionFormFields = {
  emails: string[]
}

export const ShareSubmissionModal = ({
  onClose,
  isOpen,
  submission,
}: ShareSubmissionModalProps) => {
  const toast = useToast()
  const { adminUser } = useAdminAuth()
  const link = `${HOSTNAME_WITH_PROTOCOL}/public/${submission.publicId}`

  const { mutateAsync: notify } = useNotifySubmissionMutation({
    userId: adminUser?.id,
    submissionId: submission.id,
  })

  const expiryHours = useMemo(
    () => dayjs(submission.expiresAt).endOf('day').diff(dayjs(), 'hours'),
    [submission.expiresAt],
  )

  const isExpired = useMemo(() => expiryHours <= 0, [expiryHours])

  const formMethods = useForm<ShareSubmissionFormFields>({
    mode: 'onChange',
  })
  const { handleSubmit, formState, reset } = formMethods

  const onModalClose = () => {
    reset({ emails: [] })
    onClose()
  }

  const onEmail = handleSubmit(async ({ emails }) => {
    await Promise.all(
      emails.map((email) =>
        notify(
          { email },
          {
            onSuccess: () => {
              toast({
                description: 'Submission link sent',
                status: 'success',
              })
            },
            onError: () => {
              toast({
                description: 'Something went wrong. Please try again.',
                status: 'error',
              })
            },
          },
        ),
      ),
    )
    onModalClose()
  })

  return (
    <Modal isOpen={isOpen} onClose={onModalClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Share submission link</ModalHeader>
        <ModalCloseButton />
        <ModalBody mt="12px">
          <FormProvider {...formMethods}>
            <VStack spacing={'24px'}>
              {isExpired && (
                <Infobox
                  border="1px"
                  borderColor="yellow.300"
                  borderRadius="md"
                  variant="warning"
                  w="100%"
                  icon={<WarningIcon color="yellow.400" fontSize="sm" />}
                >
                  <Text textStyle="body-1" pt="2px">
                    Link expired {-1 * expiryHours} hours ago
                  </Text>
                </Infobox>
              )}
              <FormControl
                isRequired
                isInvalid={!!formState.errors.emails}
                isReadOnly={formState.isSubmitting}
              >
                <FormLabel
                  description={
                    isExpired
                      ? 'Edit the submission expiry date before sharing this link.'
                      : 'Enter the email addresses you would like to send the link to'
                  }
                  fontSize={{ base: '1.125rem', lg: '1rem' }}
                >
                  Send link via email
                </FormLabel>
                <HStack>
                  <MultiEmailInput name="emails" isDisabled={isExpired} />
                  <IconButton
                    isDisabled={!!formState.errors.emails || isExpired}
                    isLoading={formState.isSubmitting}
                    icon={<BiMailSend />}
                    aria-label="Send via email"
                    onClick={onEmail}
                    fontSize="1.25rem"
                  />
                </HStack>
              </FormControl>
              <FormControl isRequired mb="2.5rem">
                <FormLabel fontSize={{ base: '1.125rem', lg: '1rem' }}>
                  or copy and share this link
                </FormLabel>
                <HStack>
                  <InputGroup>
                    <Input isReadOnly value={link} isDisabled={true} />
                    <InputRightElement>
                      <CopyButton
                        stringToCopy={link}
                        aria-label="Copy field ID value"
                        variant={'clear'}
                        color="brand.secondary.600"
                        isDisabled={isExpired}
                      />
                    </InputRightElement>
                  </InputGroup>
                </HStack>
              </FormControl>
            </VStack>
          </FormProvider>
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  )
}
