import { UseToastOptions } from '@chakra-ui/react'
export enum ToastMessageType {
  COLLABORATOR_ADD_SUCCESS = 'collaborator_add_success',
  COLLABORATOR_REMOVE_SUCCESS = 'remove_collaborator_success',
}

export const getToastMessage = (
  messageType: ToastMessageType,
  toastInfo: string,
): UseToastOptions | null => {
  switch (messageType) {
    case ToastMessageType.COLLABORATOR_ADD_SUCCESS:
      return {
        status: 'success',
        description: `Successfully added ${toastInfo}`,
      }
    case ToastMessageType.COLLABORATOR_REMOVE_SUCCESS:
      return {
        status: 'success',
        description: `Successfully removed ${toastInfo}`,
      }
    default:
      return null
  }
}
