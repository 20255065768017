import { BiTrash } from 'react-icons/bi'
import { Avatar, Button, HStack, Text } from '@chakra-ui/react'

interface CollaboratorRowProps {
  userEmail: string
  onClickRemove?: () => void
  isAdmin?: boolean
  isOwner?: boolean
}
export const CollaboratorRow = ({
  userEmail,
  onClickRemove,
  isAdmin,
  isOwner,
}: CollaboratorRowProps) => {
  return (
    <HStack
      justify={'space-between'}
      _hover={{
        backgroundColor: 'grey.50',
        borderRadius: '2px',
      }}
      pr={4}
    >
      <HStack>
        <Avatar
          w="30px"
          h="30px"
          size={'sm'}
          name={userEmail}
          getInitials={(collaborator: string) => collaborator.charAt(0)}
          bg={'green.300'}
        />
        <HStack spacing={'4px'}>
          <Text fontSize={'14px'}>{userEmail}</Text>
          {isAdmin && (
            <Text color="brand.secondary.500" fontSize={'14px'}>
              (You)
            </Text>
          )}
        </HStack>
      </HStack>
      {isAdmin || isOwner ? (
        <Text
          textStyle={'body-2'}
          height="44px"
          display="flex"
          alignItems="center"
        >
          {isAdmin ? 'Admin' : 'Owner'}
        </Text>
      ) : (
        <Button
          onClick={onClickRemove}
          variant={'outline'}
          color="red.500"
          p={0}
          m={0}
          isDisabled={isAdmin}
          border="none"
          aria-label="Remove collaborator"
          _hover={{ color: 'green.500' }}
          _disabled={{ color: 'grey.200', cursor: 'auto' }}
        >
          <BiTrash size="1rem" />
        </Button>
      )}
    </HStack>
  )
}
