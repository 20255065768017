import { useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { WretchError } from 'wretch/resolver'

import {
  CreateCampaignReq,
  GetCampaignDto,
  GetCampaignResponseDto,
  UpdateCampaignReq,
} from '~shared/dtos'
import { UserCampaignAccessDto } from '~shared/dtos/access.dto'

import { adminQueryKeys } from '~/constants/query-keys'
import { useAdminAuth } from '~/features/auth'
import { api } from '~/lib/api'
import { ResponseError } from '~/types/error'

export const useGetCampaigns = (
  limit: number,
  offset: number,
  userId?: number,
  searchQuery?: string,
) => {
  const { data, isLoading } = useQuery(
    adminQueryKeys.campaigns({ userId, searchQuery, limit, offset }),
    () =>
      api
        .url(
          `/users/${userId ?? ''}/campaigns?&limit=${limit}&offset=${offset}${
            searchQuery ? '&query=' + searchQuery : ''
          }`,
        )
        .get()
        .json<GetCampaignResponseDto>(),
    { enabled: !!userId, suspense: true },
  )

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return {
    campaigns: data?.campaigns,
    count: data?.count,
    isCampaignsLoading: isLoading,
  }
}

export const useCreateCampaignMutation = (
  userId?: number,
  {
    onSuccess,
    onError,
  }: {
    onSuccess?: (res: GetCampaignDto) => void
    onError?: (err: ResponseError) => void
  } = {},
) => {
  const queryClient = useQueryClient()

  return useMutation(
    async (body: CreateCampaignReq): Promise<GetCampaignDto> => {
      return await api
        .url(`/users/${userId ?? ''}/campaigns`)
        .post(body)
        .json<GetCampaignDto>()
    },
    {
      onSuccess: async (res: GetCampaignDto) => {
        await queryClient.invalidateQueries(adminQueryKeys.campaigns())
        onSuccess?.(res)
      },
      onError,
    },
  )
}

export const useUpdateCampaignMutation = (
  { campaignId, userId }: { campaignId?: number; userId?: number },
  {
    onSuccess,
    onError,
  }: {
    onSuccess?: (res: GetCampaignDto) => void
    onError?: () => void
  } = {},
) => {
  const queryClient = useQueryClient()

  return useMutation(
    async (body: UpdateCampaignReq): Promise<GetCampaignDto> => {
      return await api
        .url(`/users/${userId ?? ''}/campaigns/${campaignId ?? ''}`)
        .patch(body)
        .json<GetCampaignDto>()
    },
    {
      onSuccess: async (res: GetCampaignDto) => {
        // TODO - should optimistically update instead
        await queryClient.invalidateQueries(adminQueryKeys.campaigns())
        onSuccess?.(res)
      },
      onError,
    },
  )
}

export const useCampaignId = (): { campaignId: number } => {
  const { campaignId } = useParams()
  return { campaignId: Number(campaignId) }
}

// Composite hook that relies on URL param, useAuth()
export const useCampaign = () => {
  const { adminUser } = useAdminAuth()
  const { campaignId } = useCampaignId()
  const userId = adminUser?.id

  const { data, isLoading } = useQuery(
    adminQueryKeys.campaigns({ campaignId }),
    () =>
      api
        .url(`/users/${userId ?? ''}/campaigns/${campaignId}`)
        .get()
        .json<GetCampaignDto>(),
    { enabled: !!userId, suspense: true },
  )
  return {
    campaign: data,
    isCampaignLoading: isLoading,
  }
}

export const useGetCampaignCollaborators = (campaignId: number) => {
  const { adminUser } = useAdminAuth()
  const userId = adminUser?.id
  const { data, isLoading, error } = useQuery(
    adminQueryKeys.collaborators(campaignId),
    () => {
      return api
        .url(`/users/${userId ?? ''}/campaigns/${campaignId}/collaborators`)
        .get()
        .json<string[]>()
    },
    {
      enabled: !!campaignId && campaignId !== -1,
    },
  )

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return { collaborators: data!, isDataLoading: isLoading, error }
}

export const useAddCollaboratorAccess = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (res: [], accessDto: UserCampaignAccessDto) => void
  onError?: (errors: WretchError, accessDto: UserCampaignAccessDto) => void
} = {}) => {
  const { adminUser } = useAdminAuth()
  const userId = adminUser?.id

  const queryClient = useQueryClient()
  return useMutation(
    async (body: UserCampaignAccessDto): Promise<[]> => {
      return await api
        .url(`/users/${userId ?? ''}/campaigns/invite`)
        .post(body)
        .json<[]>()
    },
    {
      onSuccess: async (res: [], accessDto: UserCampaignAccessDto) => {
        // invalidate campaigns dashboard queries
        await queryClient.invalidateQueries(adminQueryKeys.collaborators())
        onSuccess?.(res, accessDto)
      },
      onError: (error: WretchError, accessDto: UserCampaignAccessDto) => {
        onError?.(error, accessDto)
      },
    },
  )
}

export const useRemoveCollaboratorAccess = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (res: [], accessDto: UserCampaignAccessDto) => void
  onError?: (errors: WretchError, accessDto: UserCampaignAccessDto) => void
} = {}) => {
  const { adminUser } = useAdminAuth()
  const userId = adminUser?.id
  const queryClient = useQueryClient()
  return useMutation(
    async (body: UserCampaignAccessDto): Promise<[]> => {
      return await api
        .url(`/users/${userId ?? ''}/campaigns/remove`)
        .post(body)
        .json<[]>()
    },
    {
      onSuccess: async (res: [], accessDto: UserCampaignAccessDto) => {
        // invalidate collaborators fetch queries
        await queryClient.invalidateQueries(adminQueryKeys.collaborators())
        onSuccess?.(res, accessDto)
      },
      onError: (error: WretchError, accessDto: UserCampaignAccessDto) => {
        onError?.(error, accessDto)
      },
    },
  )
}
