import { useCallback } from 'react'
import { BiTrash } from 'react-icons/bi'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UseDisclosureReturn,
  VStack,
} from '@chakra-ui/react'
import { Button, ModalCloseButton } from '@opengovsg/design-system-react'

import { GetDocumentDto } from '~shared/dtos'

import { useAdminUser } from '~/features/auth/hooks'

import { useDeleteDocument } from '../dashboard/hooks/documents.hooks'

type DeleteFileModalProps = Pick<UseDisclosureReturn, 'onClose' | 'isOpen'> & {
  document: GetDocumentDto
}

export const DeleteFileModal = ({
  onClose: onCloseDisclosure,
  isOpen,
  document,
}: DeleteFileModalProps) => {
  const { adminUser } = useAdminUser()

  const { mutateAsync, isLoading } = useDeleteDocument(
    {
      userId: adminUser?.id,
      documentId: document.id,
    },
    {
      onSuccess: () => {
        onClose()
      },
      onError: () => {
        onClose()
      },
    },
  )

  const onClose = useCallback(() => {
    onCloseDisclosure()
  }, [onCloseDisclosure])

  const handleClick = () => {
    mutateAsync()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pr="76px">
          Are you sure you would like to delete {document.name}?
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={2} align="stretch">
            <Text>
              This action will permanently delete {document.name} inside your
              submission. Once deleted, it cannot be recovered. Would you like
              to proceed?
            </Text>
          </VStack>
        </ModalBody>
        <ModalFooter justifyContent="flex-start">
          <Button
            type="submit"
            onClick={handleClick}
            isLoading={isLoading}
            leftIcon={<BiTrash size={24} />}
            colorScheme="red"
            _disabled={{
              cursor: 'not-allowed',
              border: 'none',
              color: 'interaction.support.disabled-content',
              bgColor: 'interaction.support.disabled',
            }}
          >
            Delete file
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
